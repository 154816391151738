import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import http from "@/request/http";
import ElementUI from "element-plus";
import "element-plus/dist/index.css";
import animate from "animate.css";
import echarts from "echarts";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import scroll from "vue-seamless-scroll/src";
import "./assets/dh/dh.css"
// import socket from './utils/io'

const app = createApp(App);

app.config.globalProperties.$http = http;
app.config.globalProperties.$echarts = echarts;
// app.config.globalProperties.$socket = socket;


app
  .use(store)
  .use(animate)
  .use(router)
  .use(scroll)
  .use(ElementUI, {
    locale: zhCn,
  })
  .mount("#app");
