import axios from "axios";
import Vue from "vue";
import router from "../router";
const baseURL = window.IPCONFIG;
const instance = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: baseURL,
  method: "post",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

// 拦截器配置
axios.interceptors.response.use(
  (res) => {
    if (res.data && res.data.code === "1003") {
      router.push("/");
    }
    if (!res.data.success && res.data.code !== "0") {
      if (res.data.message && !res.config.url.includes("")) {
        Vue.prototype.$message.error({
          type: "error",
          duration: 3000,
          message: res.data.message,
        });
      }
    }
    if (typeof res.data === "object" && !res.data.status) {
      res.data.status = res.status;
    }
    return res.data;
  },
  (error) => {
    // 超时情况
    if (!error.response && error.message.indexOf("timeout") >= 0) {
      error.response = {};
      error.response.data = {
        code: "timeout",
        data: null,
        message: "请求超时",
        success: false,
        status: error.response.status,
      };
      return error.response.data;
    } else {
      return error;
    }
  }
);

export default {
  GET: function (url, data, config) {
    // axios.defaults.baseURL = baseURL; // 请求地址
    return instance.get(encodeURI(url), data, config); // 请求参数
  },
  POST: function (url, data, config) {
    // axios.defaults.baseURL = baseURL; // 请求地址
    console.log("axios.defaults", data);
    return instance.post(encodeURI(url), data, config); // 请求参数
  },
  DELETE: function (url, data, config) {
    // axios.defaults.baseURL = baseURL; // 请求地址
    return instance.delete(encodeURI(url), data, config); // 请求参数
  },
};
